import './App.css';

function Tax(){
    return(
        <div className="image3">
        <div className='box'>
            <ul>
            <h1>TAX</h1>
            <li>Taxation is a specialized area of expertise that ,with intelligent planning, can result in beneficial outcomes for both Individual and Commercial clients. We take the hassle out of tracking submission deadlines, calculating taxes, and submitting tax requirements so that you can focus on your core operations.</li>
            <li>We ensure that we have provided clients with appropriate tax planning before the submission of taxes which ensures that our clients can realize optimal tax benefits.</li>
            <h2>SOME EXAMPLES OF OUR SERVICES INCLUDE</h2>
            <h3>COMMERCIAL</h3>
            <li>Commercial Taxation (Income Tax)</li>
            <li>Taxation planning and administration</li>
            <li>Monthly, Bi-Annual and Annual Submissions</li>
            <li>Monthly / Bi-Monthly SARS VAT (Value Added Tax) Submissions</li>
            <li>Annual Income Tax Submissions</li>
            <li>Quarterly Provisional Tax</li>
            <li>Monthly SDL (Skills Development Levy) Submissions</li>
            <li>Monthly UIF (Unemployment Insurance Fund) Submissions</li>
            <h3>INDIVIDUAL</h3>
            <li>Personal Income Tax Submissions</li>
            <li>Monthly SARS PAYE (Pay as you earn) Submissions</li> 
            <li className='lastp'>Quarterly Provisional Tax Submissions</li>
            <p className="copy">© Mellett's Financial Services.</p>           
            </ul>
            </div></div>
    );
}

export default Tax;