import './App.css'

function Accounting(){
    return(
        <div className="image2">
        <div className='box'>
            <ul>
            <h1>ACCOUNTING</h1>
            <li>Mellett's Financial Services provides a broad range of Accounting Services to Commercial and Individual clients.</li>
            <h2>SOME EXAMPLES OF OUR SERVICES INCLUDE</h2>
            <li>Monthly maintenance of accounting records, an in-house system, and recommendations for improvement.</li>
            <li>Monthly Bookkeeping Services (Control of Documentation, Invoicing, Statements, Supplier Reconciliations) Specialisation in Creditors and Debtor.</li>
            <li>Monthly Administration of Payroll functions (providing to the client the payment list and staff payslips).</li>
            <li>Monthly, Quarterly or Half yearly Management Accounts and/or Management Financial Statements.</li>
            <li>Annual preparations of Financial Statements.</li>
            <li>Annual Audited Financial Statements (Outsourced).</li>
            <li>BEE Certification (Outsourced).</li>
            <li className='lastp'>We also provide cost-effective rates for monthly Commercial clients and all Individual clients.</li>
            <p className="copy">© Mellett's Financial Services.</p>
            </ul>
        </div></div>
    );
}

export default Accounting;