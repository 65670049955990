function Sec(){
    return(
        <div className="image4">
        <div className='box'><ul>
            <h1>SECRETARIAL</h1>
            <li>Mellett's Financial Services is able to take care of all your Commercial and Individual statutory requirements. Our services ensure you have more time to focus on developing your business while assuring you that all your paperwork is up-to-date and on track!</li>
            <h2>COMMERCIAL</h2>
            <li>Registrations of New Companies</li>
            <li>Changes to Shelf Closed Corporations</li>
            <li>Unemployment Insurance Fund (UIF) Registrations and Management</li>
            <li>Statutory Registrations with the South African Revenue Services (SARS)</li>
            <li>Workmens Compensation Fund Registration and Management</li>
            <li>BEE - Certification (Outsourced)</li>
            <h2>INDIVIDUAL</h2>
            <li>Personal Income Tax Registrations</li>
            <li className='lastp'>Statutory Registrations with the South African Revenue Services (SARS)</li>
            <p className="copy">© Mellett's Financial Services.</p>
            </ul></div></div>
    );
}

export default Sec;