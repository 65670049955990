import './App.css';


function About () {


    
    return(
        <div className="image1">
        <div className='box'>
            <ul>
            <h1>ABOUT</h1>
            <li>Mellett's Financial Services was established in 2006 by Tracey Kilian and Therese Mellett. We pride ourselves in offering the highest levels of service delivery and professionalism.</li>
            <h2>GEOGRAPHICAL LOCATION</h2>
            <li>Our head office is located in Pretoria and we assist clients primarily within this region. However, we service clients as far as Cape Town and we welcome new clients throughout South Africa.</li>
            <h2>OUR MISSION</h2>
            <li>Our mission is to provide our clients with professional, innovative, and efficient financial services. We offer guidance from the formation of their business, through to the successful and sustainable operation of their business on a day-to-day basis.</li>
            <h2>HOW WE WORK</h2>
            <li>We are different from our competitors in that we provide exceptional personalized service. We are committed to providing a full range of individualized, industry-specific, accounting services which we achieve through conducting in-depth research into each new client's industry and their unique requirements. Based on our research and our understanding of our client's business and operations, we formulate, propose and implement tailor-made, innovative, and time-saving systems and solutions that add value to our client's business and assist them to focus on their core operations. With Mellett's Financial Services, your accounting and tax affairs become straightforward. You can confidently leave the time and hassle of accounting and taxation to us, knowing that everything will be taken care of!</li>
            <h2>WHO WE SERVE</h2>
            <li>Mellett's Financial Services assists individual clients within a broad range of financial portfolios and an exceptionally diverse range of commercial clients across all industries. Regardless of the size or scope of your  financial portfolio or your organization, we strive to provide you with a personalized, yet professional and efficient service that is suitable to your individual needs and future ambitions.</li>
            <h2>CHARGES</h2>
            <li className='lastp'>We provide our services based on an hourly rate. We believe that this provides a more open and flexible manner of providing services. It is our policy at Mellett's Financial Services to go the extra mile and as such we frequently offer added value services over and above what we have quoted in order to benefit the individual needs of our client.</li>
            <p className="copy">© Mellett's Financial Services.</p>
            </ul>
        </div></div>
    );
}

export default About;