import './App.css';

function Consult(){
    return(
        <div className="image5">
        <div className='box'><ul>
            <h1>CONSULTING SERVICES</h1>
            <li>Mellett's Financial Services can help CEOs and CFOs to optimize their finance functions. We help you to control, efficiently characterize, and streamline your back office models.</li>
            <li>By being accountable to businesses and the financial community, CFOs have or should be putting compliance and control at the top of their agenda. Being flexible enough to respond to future regulatory change is also increasingly important. The pressure to release money and resources has never been greater. Businesses and Business environments thrive from the elimination of redundant activities, standardization, and automation of processes. Our processes have in some instances resulted in cost savings above 50% by providing insight and challenge</li>
            <h2>We can help you if...</h2>
            <li>You are encountering more challenges than expected.</li>
            <li>You are trying to provide an effective performance management system.</li>
            <li>You wish to reduce the costs and improve the efficiency of the finance department.</li>
            <li>You want more vision over finance.</li>
            <li className='lastp'>You need to align finance with the business.</li>
            <p className="copy">© Mellett's Financial Services.</p>

            </ul></div></div>
    );
}

export default Consult;