import { Routes, Route } from "react-router-dom";
import './App.css';
import Contact from "./contact";
import Consult from "./consulting";
import Sec from "./secreterial";
import Tax from "./tax";
import Accounting from "./accounting";
import About from "./aboutMelletts";
import { Link } from "react-router-dom";
import logo from './logo.png';
import Button from 'react-bootstrap/Button';


function App() {
  return (
    <>
    <div className="navbar bg-image">
      <div className="big">
      <Link to="main"><img src={logo} className="App-logo" alt="logo"></img></Link>
      </div>
      <Link classname="anc" to="About Melletts"><Button variant="primary bnt">Home</Button>{''}</Link>
      <Link classname="anc" to="Accounting"><Button variant="primary bnt">Accounting</Button>{''}</Link>
      <Link classname="anc" to="Tax"><Button variant="primary bnt">Tax</Button>{''}</Link>
      <Link classname="anc" to="Secreterial"><Button variant="primary bnt">Secreterial</Button>{''}</Link>
      <Link classname="anc" to="Consulting"><Button variant="primary bnt">Consulting</Button>{''}</Link>
      <Link classname="anc" to="Contact"><Button variant="primary bnt">Contact</Button>{''}</Link>
    </div><div>
        <Routes>
          <Route path="/" element={<About />}/>
          <Route path="About Melletts" element={<About />}/>
          <Route path="Accounting" element={<Accounting />} />
          <Route path="Tax" element={<Tax />} />
          <Route path="Secreterial" element={<Sec />} />
          <Route path="Consulting" element={<Consult />} />
          <Route path="Contact" element={<Contact />} />
        </Routes>
      </div>
      </>
  );
}

export default App;
