import './App.css';

function Contact(){
    return(
        <div className="image6">
        <div className='box'>
            <ul>
                <h1>GET IN TOUCH WITH US!</h1>
                <p>We would like to thank you for your interest in Mellett's Financial Services.
                   Please feel free to contact us using the details provided below.</p>
                <p className='con'>Therèse Mellett (Accountant)</p>
                    <li>Cell No: +27 (0)82 729 6327</li>
                    <li>SAIT Membership No: 28155132</li>
                    <li className='conli'>Tax Practioner No: PR0022088</li>
                <p className='con'>Tracey Kilian (Admin Manager)</p>
                    <li className='conli'>Cell No: +27 (0)76 771 8483</li>
                <p className='con'>Business Information:</p>
                <li>Email: info@mellettsfinserv.co.za</li>
                <li>Facebook: <a classname="lnk" href="https://www.facebook.com/pages/Melletts-Financial-Services/471118486351391">@Melletts-Financial-Services</a></li>
                <li className='lastp'>Twitter : <a classname="lnk" href="https://twitter.com/mellett_s">@mellett_s</a></li>
                <p className="copy">© Mellett's Financial Services.</p>
            </ul>
        </div></div>
    );
}

export default Contact;